/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { yearMapping } from "./general";
import { getSyslocale } from "./locale";

const pkCreditsumValue = $("#pk_creditsum_value").val();
const pkRuntimeValue = $("#pk_runtime_value").val();
const pkPurposeValue = $("#pk_purpose_value").val();

window.effectiveRate = 5.32;
const url = new URL(window.location.href).pathname;

function getAmount() {
  if (url.indexOf("autokredit") !== -1) {
    return numeral($("#form-pk-akStart-input-creditSum").val()).value();
  } else if (url.indexOf("umschuldung") !== -1) {
    return numeral($("#form-pk-usStart-input-creditSum").val()).value();
  }
  return numeral($("#form-pk-start-input-creditSum").val()).value();
}

function getRuntime() {
  let val = 0;
  if (url.indexOf("autokredit") !== -1) {
    val = $("#form-pk-akStart-select-creditPeriod").val();
  } else {
    val = $("#form-pk-start-select-creditPeriod").val();
  }
  if (yearMapping[val]) return yearMapping[val];
  if ($("#lsf_range_year").length > 0) {
    return $("#lsf_range_year").val() / 12;
  } else {
    return Number($("#pk_duration div.active").html()) / 12;
  }
}

function setMonthlyRate(mtl) {
  $("#pk-form-start").data("monthlyRate", numeral(mtl).format("0,0.00"));
  $("#mtl_rate").html(numeral(mtl).format("0,0.00"));
  $("#mtl_rate_input").val(numeral(mtl).format("0,0.00"));
}

function calcMonthlyRate(am = getAmount(), runtime = getRuntime()) {
  const amount = typeof am === "string" ? Number(am) : am;
  const months = Number(runtime) * 12;
  return (
    (amount * (window.effectiveRate / 1200)) /
    (1 - Math.pow(1 / (1 + window.effectiveRate / 1200), months))
  );
}

function onChangeYear(e) {
  setMonthlyRate(
    calcMonthlyRate(getAmount(), getRuntime($(e.currentTarget).val()))
  );
}

function initCreditPeriodSlider() {
  // only init when we have that element ...
  if ($("#form-pk-start-slider-area").length) {
    function onChangeCPS(amount, runtime) {
      const years = Number(runtime) / 12;
      const rate = calcMonthlyRate(amount, years);
      $("#form-pk-start-slider-area .months").text(
        runtime.toFixed() + " Monate"
      );
      $("#form-pk-start-slider-area .years").text(
        "(" + Math.floor(years) + " Jahre)"
      );
      $("#lsf_range_year").val(runtime);
      const creditRate = numeral(rate).format("0,0.00");
      $("#mtl_rate").html(creditRate);
      $("#mtl_rate_input").val(creditRate);
      $("#pk_year").val(runtime.trim());
    }
    createSlider(
      "form-pk-start-slider-area",
      12,
      144,
      1,
      84,
      function (e, r) {
        const amount = numeral(
          $("#form-pk-start-input-creditSum").val()
        ).value();
        const runtime = r.value;
        onChangeCPS(amount, runtime);
      },
      function (e, r) {},
      function () {
        const amount = numeral(
          $("#form-pk-start-input-creditSum").val()
        ).value();
        const runtime = $("#lsf_range_year").val();
        onChangeCPS(amount, Number(runtime));
        $("#form-pk-start-input-creditSum").on("input", function (event) {
          const $this = $(this);
          const input = $this.val();
          const parsedInput = numeral(input);
          const limit = 100000000;
          $this.val(function () {
            if (parsedInput.value() >= limit) {
              return numeral(limit).format("0,0");
            }
            if (parsedInput.value() === null) return "";
            return parsedInput.format("0,0");
          });
          const amount = parsedInput.value();
          const runtime = $("#lsf_range_year").val();
          onChangeCPS(amount, Number(runtime));
        });
      }
    );
  }
}

function initCreditPeriodBoxed() {
  function onChangeCPB(amount, runtime) {
    const years = Number(runtime) / 12;
    const rate = calcMonthlyRate(amount, years);
    // $("#mtl_rate").html(numeral(rate).format("0,0.00"));
    setMonthlyRate(rate);
  }
  if ($("#pk_duration").length) {
    $("#pk_duration div").on("click", function () {
      $("#pk_duration div").removeClass("active");
      $(this).addClass("active");
      const runtime = $(this).html();
      let amount = 0;
      if (url.indexOf("umschuldung") !== -1) {
        amount = numeral($("#form-pk-usStart-input-creditSum").val()).value();
      } else {
        amount = numeral($("#form-pk-start-input-creditSum").val()).value();
      }
      $("#pk_year").val(runtime.trim());
      onChangeCPB(amount, Number(runtime));
    });

    $("#form-pk-start-input-creditSum").on("input", function (e) {
      let $this = $(this);
      let input = $this.val();
      let parsedInput = numeral(input);
      let limit = 100000000;
      if (parsedInput.value() == null) {
        $(e.currentTarget).removeData("value");
      }
      $this.val(function () {
        if (parsedInput.value() < 0) {
          $(e.currentTarget).data("value", parsedInput.value());
          return numeral(-1 * parsedInput.value()).format("0,0");
        }
        if (parsedInput.value() >= limit) {
          $(e.currentTarget).data("value", 0);
          return numeral(limit).format("0,0");
        }
        if (parsedInput.value() === null) {
          $(e.currentTarget).data("value", undefined);
          return "";
        }
        $(e.currentTarget).data("value", parsedInput.value());
        return parsedInput.format("0,0");
      });
      const amount = parsedInput.value();
      const runtime = $("#pk_year").val();
      onChangeCPB(amount, Number(runtime));
    });

    $("#pk_duration div.active").click();
  }
}

function init() {
  $("#form-pk-start-select-creditPeriod")
    .siblings("div.options")
    .children("a[data-parent=seven_years]")
    .trigger("click");
  $("#form-pk-akStart-select-creditPeriod")
    .siblings("div.options")
    .children("a[data-parent=seven_years]")
    .trigger("click");
  $("#form-pk-usStart-select-creditPeriod")
    .siblings("div.options")
    .children("a[data-parent=seven_years]")
    .trigger("click");
  setMonthlyRate(calcMonthlyRate());
}

// eslint-disable-next-line no-unused-vars
function onChangeX(e) {
  $("#form-pk-start-input-creditSum").val(e.from);
  $("#form-pk-akStart-input-creditSum").val(e.from);
  $("#form-pk-usStart-input-creditSum").val(e.from);
  setMonthlyRate(calcMonthlyRate());
}

// eslint-disable-next-line no-unused-vars
function onUpdateX(e) {
  $("#form-pk-start-input-creditSum").val(e.from);
  $("#form-pk-akStart-input-creditSum").val(e.from);
  $("#form-pk-usStart-input-creditSum").val(e.from);
}

function onChangeSum(e) {
  setMonthlyRate(calcMonthlyRate());
}

function createSlider(id, min, max, step, start, slide, change, init) {
  let sliderBar;
  let rangeLabel;
  let sliderHandle;
  let triangle;
  let l = function () {
    rangeLabel.offset({
      left: Math.min(
        sliderBar.offset().left + sliderBar.width() - rangeLabel.width(), // right side of the bar
        Math.max(
          sliderBar.offset().left, // left side of the bar
          sliderHandle.offset().left -
            rangeLabel.width() / 2 +
            Math.floor(sliderHandle.width() / 2)
        )
      ),
    });
    triangle.offset({
      left: sliderHandle.offset().left + triangle.width() / 2,
    });
  };

  let d = {
    min,
    max,
    step,
    value: start,
    slide: function (e, n) {
      slide(e, n);
      l();
    },
    change: function (t, e) {
      l();
    },
  };
  Intl.NumberFormat().format(d);
  sliderBar = $("#" + id + " .price").slider(d);
  let runtime = sliderBar.slider("value");
  let amount = $("#interaction-pk-inputLoanAmount").val();
  // calc(runtime, amount);
  rangeLabel = $("#" + id + " .amount")
    .parent()
    .parent(".range-label");
  sliderHandle = sliderBar.children(".ui-slider-handle");
  triangle = $("#" + id + " .range-label_triangle");
  l();
  $("#lsf_range_year").val(start);
  if (init && typeof init === "function") {
    init(sliderBar);
  }
}

$(() => {
  window.calcMonthlyRate = calcMonthlyRate;
});
