export const yearMapping = {
  one_year: 1,
  two_years: 2,
  three_years: 3,
  four_years: 4,
  five_years: 5,
  six_years: 6,
  seven_years: 7,
  eight_years: 8,
  nine_years: 9,
  ten_years: 10,
};
